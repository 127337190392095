import '@/assets/globals.css'
import App from 'next/app'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'


// controllers
import { useStore } from '@/controllers/redux/Store'
import React from 'react';

// components
import Loading from '@/components/Loading'



// 
// 
function MyApp({ Component, ...rest }) {


	const store = useStore(rest.initialReduxState)
	const persistor = persistStore(store, {}, function () {
		persistor.persist()
	})


	// 
	// 
	return(
		<Provider store={store}>
			<PersistGate loading={<Loading status={true}/>} persistor={persistor}>
				<Component {...rest.pageProps} />
			</PersistGate>
		</Provider>
	)
}



MyApp.getInitialProps = async (context) => {

	const ctx = await App.getInitialProps(context)
	return { ...ctx }

}

export default MyApp
