import React from 'react';


//
//
const Loading = (props) => {

  return (
    <div className={`_loading ${props.cssClass} ${props.status ? ' ' : ' hide'}`}>
      <i></i>
    </div>
  )

}

export default Loading