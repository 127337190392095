const auth = (state = {}, action) => {
	switch(action.type){
		case "AUTHSTATUS":
			return {
				...state,
				payload: action.payload
			}
		case "AUTHUPDATE":
			return {
				...state,
				payload: action.payload || state.payload
			}
		case "AUTHLOGOUT":
			return {
				...state,
				payload: null
			}
		default:
			return state
	}
}

export default auth;
